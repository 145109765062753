import calculateDiscountedPrice from './calculateDiscountedPrice';

export const calculateTotalWithDiscounts = (basket, onePlusOne = false) => {
    if (!basket || basket.length === 0) return 0;

    // Group items by type and size
    const groupedItems = {};

    basket.forEach(item => {
        const key = `${item.productType}_${item.selectedSize}`;
        if (!groupedItems[key]) {
            groupedItems[key] = {
                totalUnits: 0,
                items: []
            };
        }
        groupedItems[key].totalUnits += item.units;
        groupedItems[key].items.push(item);
    });

    // Calculate total with discounts applied to groups
    let total = 0;

    Object.values(groupedItems).forEach(group => {
        if (onePlusOne) {
            // Calculate how many items should be free (half of the total units in group, rounded down)
            const freeItems = Math.floor(group.totalUnits / 2);
            const paidItems = group.totalUnits - freeItems;

            // Distribute paid items proportionally across the items in the group
            let remainingPaidItems = paidItems;

            group.items.forEach((item, index) => {
                const itemShare = Math.round((item.units / group.totalUnits) * paidItems);
                const itemPaidUnits = index === group.items.length - 1 ?
                    remainingPaidItems : // Last item gets remaining units
                    itemShare;

                total += itemPaidUnits * item.price;
                remainingPaidItems -= itemPaidUnits;
            });
        } else {
            // No 1+1 discount, calculate normal price
            group.items.forEach(item => {
                total += item.units * item.price;
            });
        }
    });

    return total;
};

export default calculateTotalWithDiscounts;