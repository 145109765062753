import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { OrderContext } from "../components/providers/OrderProvider";
import Footer from "../components/Footer";

const ColoringType = ({ step }) => {
  const { order, setOrder, setUseMaxSize } = useContext(OrderContext);

  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onColoringTypeContainerClick = useCallback(() => {
    setUseMaxSize(false);
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        type: "regrowth",
        status: "filling",
      })
    );

    setOrder((order) => ({
      ...order,
      type: "regrowth",
      status: "filling",
    }));

    navigate("/colors");
  }, [navigate]);

  const onColoringTypeContainer1Click = useCallback(() => {
    setUseMaxSize(true);
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        type: "lengths",
        status: "filling",
      })
    );

    setOrder((order) => ({
      ...order,
      type: "lengths",
      status: "filling",
    }));

    navigate("/colors");
  }, [navigate]);

  const onMaskContainerClick = useCallback(() => {
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        type: "regrowth",
        status: "filling",
      })
    );

    setOrder((order) => ({
      ...order,
      type: "regrowth",
      status: "filling",
    }));

    navigate("/masks");
  }, [navigate]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-between min-h-screen">
      <div className="w-full overflow-hidden flex flex-col items-center justify-start">
        <Navbar onLogoClick={onLogoClick} step={step} />
        <main>
        <div className="relative self-stretch overflow-hidden flex lg:flex-col py-[2.5rem] px-[2rem] items-center lg:justify-start justify-center gap-x-[6rem] gap-y-[2.5rem] text-center text-[1rem] text-color-black font-graphie md:gap-[3rem] md:py-[2rem] md:px-[1.5rem] md:box-border sm:gap-[3rem] sm:py-[2rem] sm:px-[1.5rem] sm:box-border">
            <section className="space-y-[2rem] sm:space-y-[1.5rem]">
              <header className="w-full self-stretch flex flex-col items-center justify-start text-center text-[1.5rem] text-color-black font-ivy-mode">
                <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit lg:text-[3rem] md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
                  Barvy
                </h1>
                <strong className="text-dimgray font-normal font-graphie">
                  Co budeš barvit?
                </strong>
              </header>
              <div className="w-full max-w-[40rem] my-0 mx-auto self-stretch flex flex-row sm:flex-col items-start justify-start gap-[1.5rem] hover:cursor-auto">
                <div
                  className="flex-1 rounded-3xl bg-color-white overflow-hidden flex flex-col pt-[1rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[1rem] cursor-pointer border-[1px] border-solid border-color-grey-200 hover:bg-color-grey-100 hover:box-border hover:cursor-pointer hover:border-[1px] hover:border-solid hover:border-color-grey-100 md:pl-[1.5rem] md:pr-[1.5rem] md:pb-[1.5rem] md:box-border md:hover:bg-color-grey-100 md:hover:box-border md:hover:border-[1px] md:hover:border-solid md:hover:border-color-grey-100 sm:pl-[1.5rem] sm:pr-[1.5rem] sm:pb-[1.5rem] sm:box-border sm:w-full"
                  onClick={onColoringTypeContainerClick}
                >
                  <div className="self-stretch flex flex-row items-center justify-center md:w-auto md:h-auto">
                    <img
                      className="relative w-[10.25rem] h-[10.25rem] md:w-[7.5rem] md:h-[7.5rem] sm:w-[6.5rem] sm:h-[6.5rem]"
                      alt="Odrosty"
                      src="/illustration.svg"
                      width="164"
                      height="164"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
                    <div className="relative leading-[120%] font-semibold flex items-center justify-center w-[11.63rem]">
                      Odrosty
                    </div>
                    <div className="relative leading-[120%] text-dimgray flex items-center justify-center w-[11.63rem]">
                      220 Kč / barva
                    </div>
                  </div>
                </div>
                <div
                  className="flex-1 rounded-3xl bg-color-white overflow-hidden flex flex-col pt-[1rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[1rem] cursor-pointer border-[1px] border-solid border-color-grey-200 hover:bg-color-grey-100 hover:box-border hover:cursor-pointer hover:border-[1px] hover:border-solid hover:border-color-grey-100 md:pl-[1.5rem] md:pr-[1.5rem] md:pb-[1.5rem] md:box-border sm:w-full"
                  onClick={onColoringTypeContainer1Click}
                >
                  <div className="self-stretch flex flex-row items-center justify-center">
                    <img
                      className="relative w-[10.25rem] h-[10.25rem] md:w-[7.5rem] md:h-[7.5rem] sm:w-[6.5rem] sm:h-[6.5rem]"
                      alt="Celé vlasy"
                      src="/illustration1.svg"
                      width="164"
                      height="164"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
                    <div className="relative leading-[120%] font-semibold flex items-center justify-center w-[11.63rem]">
                      Celé vlasy
                    </div>
                    <div className="relative leading-[120%] text-dimgray flex items-center justify-center w-[11.63rem]">
                      440 Kč / barva
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="lg:w-full lg:max-w-[40rem]">
              <hr className="w-px lg:w-full bg-color-grey-200 border-0 lg:h-px lg:left-0 h-[calc(100%-5rem)] lg:static absolute top-[2.5rem] m-0" />
            </div>
            <section className="space-y-[2rem] sm:space-y-[1.5rem]">
              <header className="w-full self-stretch flex flex-col items-center justify-start text-center text-[1.5rem] text-color-black font-ivy-mode">
                <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit lg:text-[3rem] md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
                  Masky
                </h1>
                <strong className="font-normal font-graphie rounded-full bg-color-green py-[0.344rem] px-[0.75rem] text-sm text-white">
                  Nově i sáčky
                </strong>
              </header>
              <div className="w-full max-w-[40rem] my-0 mx-auto self-stretch flex flex-row sm:flex-col items-start justify-start gap-[1.5rem] hover:cursor-auto">
                <div
                  className="flex-1 rounded-3xl bg-color-white overflow-hidden flex flex-col items-center justify-start gap-[1.5rem] cursor-pointer border-[1px] border-solid border-color-grey-200 hover:bg-color-grey-100 hover:box-border hover:cursor-pointer hover:border-[1px] hover:border-solid hover:border-color-grey-100 md:box-border sm:w-full"
                  onClick={onMaskContainerClick}
                >
                  <div className="self-stretch flex flex-row items-center justify-center p-[0.5rem] pb-0">
                    <img
                      className="relative w-[10.25rem] h-[10.25rem] md:w-[7.5rem] md:h-[7.5rem] sm:w-[6.5rem] sm:h-[6.5rem]"
                      alt="Maska Hair@Home"
                      src="/maska.svg"
                      width="164"
                      height="164"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem] pb-[2.5rem] px-[2.5rem] md:pl-[1.5rem] md:pr-[1.5rem] md:pb-[1.5rem]">
                    <div className="relative leading-[120%] font-semibold flex items-center justify-center w-[11.63rem]">
                      Maska Hair@Home
                    </div>
                    <div className="relative leading-[120%] text-dimgray flex items-center justify-center w-[11.63rem]">
                      od 89 Kč
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section className="max-w-[77.5rem] w-[calc(100%-48px)] mx-auto mb-10 mt-8">
            <div className="py-6 px-10 pr-8 bg-color-grey-100 rounded-3xl flex flex-row justify-between gap-4 sm2:flex-col sm2:items-center">
              <div className="flex flex-col gap-4 max-w-[40rem]">
                <h2 className="font-ivy-mode font-bold text-5xl leading-9 text-color-black py-0 my-0">
                  Potřebuješ poradit s odstínem?
                </h2>
                <p className="font-graphie font-normal text-base leading-6 text-color-grey-600 py-0 my-0">
                  Stáhni si{" "}
                  <a
                    href="https://hairathome.cz/#top"
                    targer="_blank"
                    className="no-underline text-color-black font-semibold transition-all hover:text-color-grey-600"
                  >
                    aplikaci Hair@Home
                  </a>{" "}
                  a využij na výběru barev funkci „Potřebuji poradit“. Bezplatně
                  ti poradím, jaká barva je pro tebe nejlepší.
                </p>
              </div>
              <div className="relative min-w-[9.375rem]">
                <img
                  src="/libor-kadernik.svg"
                  alt="Potřebuješ poradit s odstínem?"
                  className="absolute bottom-0 sm2:relative"
                />
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
      {/*<aside className="max-w-3xl relative self-stretch bg-color-green bg-opacity-30 rounded-3xl p-[2rem] lg:p-[1.5rem] flex flex-col gap-[0.75rem] m-[1.5rem_auto] lg:m-[1.5rem_2rem]">
        <h2 className="font-ivy-mode my-0">Akce 2+1 zdarma</h2>
        <p className="text-black my-0 opacity-75 leading-normal pr-32 lg:pr-8">
          Vlož do košíku 2 masky a barvu a získáš slevu 220 Kč, tedy lahvičku
          barvy zdarma!
        </p>
        <img
          src="/2masky-plus-barva_prazdne_bila.png"
          alt="akce 2+1 zdarma"
          width="256"
          height="254"
          className="absolute -top-4 right-4 w-32 h-32 lg:w-24 lg:h-24 object-contain"
        />
  </aside>*/}
    </div>
  );
};

export default ColoringType;
